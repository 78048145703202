@media only screen and (max-width: 500px){
    html,body{
 width: 100%;
        overflow-x:hidden!important ;
    }
    .products-actions{
        width: 105%!important;
    padding: 4%;
    background-color: white;
    margin-bottom: 2%;
    margin-right: -2%!important;
    }

    .complete-row{
        margin-top: 45%;
    }

    .sign-on-button{
        width: 50%;
        background-color: #47B362;
        padding: 2% 5%;
        border-radius: 50px;
        margin-top: 10%!important;
        border:none;
        color: white;
        margin-left: 30%;
    }

    .input-check {
        width: 4%!important;
        float: left;
        margin-top: 7%!important;
    }
    .input-check-label {
        width: 89%!important;
        float: right;
        font-size: 9pt;
        font-weight: 400;
    }
    

    .products-actions a {
        margin-right: 1%;
        border: solid 1px rgb(105, 104, 104);
        padding: 4%;
        border-radius: 5px;
        color: black;
        float: left;
    }

    .main-save-button {
        float: right;
        padding: 5.5%;
    }

    .add-product-colum input[type="file"] {
        width: 100%!important;
        border: solid 1px black;
        border-radius: 5px;
        padding: 7%;
    }

    .add-product-colum button {
        float: right;
        padding: 4%;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 8%;
    }

    .product-images{
        width: 40%;
        float:left;
        margin-left: 2.5%;
    }

    .input-price input {
        width: 90%;
        margin-left: 3%;
    }

    .add-product-colum input[type="checkbox"] {
        width: 13%;
        margin-right: 2%;
        float: left;
        margin-top: 1%;
    }

    .desktop-menu{
        display: none!important;
    }
    .mobile-menu{
        display: block!important;
    }

    .mobile-menu img{
        width: 100%;
    }
    .desktop-menu{
        display: none!important;
    }
    .mobile-menu{
        display: block!important;
    }

    .mobile-menu img{
        width: 100%;
    }

    .home-hero-banner img {
        width: 102%;
        position: absolute;
        z-index: -1;
        margin-top: 0%!important;
        
    }

    .container-fluid{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    .news-letter-footer h4 {
        font-size: 24pt;
        color: white;
        font-weight: 800;
        padding-top: 15%;
    }

    .news-letter-footer input {
        width: 90%;
        padding: 1% 2%;
        border-radius: 11px;
        border: none;
    }
    .news-letter-footer button {
        background-color: #4FAC81;
        border-radius: 11px;
        border: none;
        padding: 1% 2%;
        color: white;
        width: 90%;
        margin-top: 4%;
    }

    .overkapping-desktop{
        width: 100%;
        margin-left: -5%!important;
    }

    .home-text h1 {
        color: white;
        font-weight: 800;
        position: static;
        bottom: 10;
        font-size: 12pt;
        line-height: 1;
        margin-top: -18%;
    }

    .search-row-col h2 {
        background-color: #47B362;
        width: 90%;
        color: white;
        font-size: 12pt!important;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        padding: 1% 2% 0.5% 4%;
        margin-top: -12%;
    }
    .search-row {
        width: 90%;
        margin-left: 0%;
        margin-top: -4.1%;
        background-color: white;
        padding: 8% 1%;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        position: relative;
        z-index: 1;
        margin-bottom: 15%;
    }

    /* change ham image to close */
    .showClose {
        background-image: url("https://ljc-dev.github.io/testing0/ham-close.svg");
      }
      
      .navbar-mobile {
        position: fixed;
        top: 0;
        left: 0;
        background: #47B362;
        width: 111%;
        height: 120vh;
        overflow: hidden;
        color: white;
        z-index: 800;
        transform: translateY(-130%);
        transition: transform 0.2s ease;
      
        margin-left: -2%;
        margin-right: -34%!important;
      
      }

      .highlighted-picture img{
          margin-top: 5%;
      }
      
      
      .showNav {
        /* show the menu */
        transform: translateY(-10%);
      }
      
      /* basic nav styling below */
      
      .navbar-mobile   ul {
        width: 100%;
        height: 100%;
        list-style: none;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        margin-left: -5%;
      }
      
      .navbar-mobile  ul li a {
        color: white;
        padding-bottom: 10px;
        border-bottom: 2px solid white;
        text-decoration: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.2rem;
      }
      
      .navbar-mobile  ul li {
        width: 100%;
        margin-bottom: -48%;
        margin-top: -61%;
        margin-left: -6%;
        text-align: center;
      }
      
      .navbar-mobile  ul li a:hover, .navbar-mobile  ul li a:focus {
        border-bottom: 2px solid white;
      }

      .ham {
        position: absolute;
        z-index: 1000;
        top: 20px;
        right: 40px;
        width: 44px;
        height: 36px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        background-image: url(https://ljc-dev.github.io/testing0/ham.svg);
        background-size: 100%;
      }

      .mobile-menu{
      background-color: white;
      width: 111%;

      }

      .mobile-menu img{
        width: 40%;
        margin-top: 6%;
        margin-bottom: 5%;
        margin-left: 6%;
      }

      .half-col-image img {
        width: 95%;
        margin-left: 5%;
        margin-top: 15%;
    }

    .contact-block {
         margin-top: 0%; 
        padding-left: 0%; 
         padding-right: 0%; 
    }

    .chat{
        margin-left: 0%;
        text-align: center;
    }
    .contact-block p{
        text-align: center;
    }

    .contact-block ul li{
        text-align: center;
    }

    .faq-row{
        padding:2%;
    }
    .faq-col i {
        float: right;
        margin-top: 2.5%;
        color: black;
        font-weight: bold;
        width: 2%;
        margin-right: 2%;
    }

    .shopkeeper-col {
        width: 100%!important;
        background-color: white;
        margin-right: 1%;
        float: left;
        border-radius: 17px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        margin-bottom: 8%;
    }

    .overkapping-small {
        width: 92%!important;
        position: absolute;
        margin-left: -0.1%;
    }
    .blob-shape {
        margin-top: -26%;
        width: 100%;
    }
    .shop-summary-inside img {
        width: 100%;
        margin-right: 2%;
    }

    .product-shopinfo{
        width: 100%;
        float: left;
    }

    .product-shopping-bag-image img {
        width: 85%;
        background-color: white;
        border-radius: 14px;
        border: rgba(0, 0, 0, 0.315) 1px solid;
    }

    .shopping-bag .product-title-shoppinglist {
        margin-right: 50%;
        margin-left: 3%;
    }

    .product-title-shoppinglist {
        margin-right: 4%;
        margin-left: 0%!important;
        float: left;
        width: 100%;
        margin-top: 5%;
    }
    .product-amount-shoppinglist {
        margin-right: 4%;
        margin-left: 64%!important;
        float: left;
        width: 8%;
        margin-top: -26%;
    }

    .product-price-shoppinglist {
        margin-right: 4%;
        margin-left: 8%!important;
        float: left;
        width: 18%;
        margin-top: -26%;
    }
    .shopping-bag .checkout-shoppinglist li {
        margin-bottom: 13%;
        width: 100%;
    }

    .shopping-bag .pay-bill-button {
        width: 100%;
        background-color: #47B362;
        border: none;
        padding: 1.5% 2%;
        font-weight: 800;
        float: right;
        margin-bottom: 8%;
    }
    .product-price-shoppinglist-checkout {
        margin-right: 4%;
        margin-left: 3%;
        float: right;
        width: 13%;
        margin-top: 7%;
        text-align: right;
    }
    .product-amount-shoppinglist-checkout{
    margin-right: 4%;
    margin-left: 3%;
    float: left;
    width: 6%;
    margin-top: 7%;
}

.product-checkout-image img{
display: none;
}

.product-title-shoppinglist-checkout {
    margin-right: 4%;
    margin-left: -19%!important;
    float: left;
    width: 38%;
    margin-top: 7%;
}
.product-price-shoppinglist-checkout {
    margin-right: 4%;
    margin-left: 3%;
    float: right;
    width: 20%;
    margin-top: 7%;
    text-align: right;
}
.StripeElement {
    width: 318px!important;
    margin-left: 5px!important;
}
.firstblock-thankyou, .secondblock-thankyou, .thirdblock-thankyou {
    font-size: 9pt;
}
.right-title h2 {
    margin-bottom: 10%;
    margin-top: 16%;
}

.thank-you-block p {
    margin-left: 60px;
    margin-top: -9%;
}
.contact-block h3 {
    text-align: center;
    color: black;
}
.input-full-width {
    width: 95%;
    float: left;
    margin-left: 5%;
}

.contact-block .subtmit-button {
    width: 90%;
    padding: 2.5% 3%;
    background-color: #47B362;
    color: white;
    border-radius: 22px;
    border: none;
    margin-top: 5%;
    margin-left: 5%!important;
    margin-bottom: 40%;
}

.succes-send-form {
    position: absolute;
    right: 0;
    background-color: #28654B;
    padding: 4% 3% 0% 3%;
    margin-right: 5%;
    border-radius: 22px;
    margin-top: -35%!important;
    margin-left: 5%;
    width: 90%;
    color: white;
}

.cta-row {
    margin-top: 8%;
    margin-bottom: 20%;
}
.quick-start-stepts-col-number p {
    font-size: 35pt!important;
    text-align: center;
    font-weight: 800;
    color: #00000056;
    margin-top: 3%;
}
.half-col-text{
    text-align: center;
}

.sign-on-page-image{
    display: none;
}

.faq-col {
    background-color: #c2d8dd;
    border-radius: 19px;
    padding: 4.5% 5% 2% 7%;
    margin-bottom: 2%;
}

.faq-row-para p{
    color: black;
}

.home-banner-col-items h2 {
    color: white;
    line-height: 1em;
    font-family: "Flamante SemiSlab";
    font-size: 3em;
}
.home-banner-col-items h3 {
    color: white;
    margin-bottom: 8%;
    font-family: "Photograph Signature";
    font-size: 4em;
    font-weight: 300;
}

.postal-field label {
    background-color: white;
    padding: 1%;
    border: none;
    width: 100%;
}
.postal-field input {
    width: 100%;
    padding: 1%;
    border: none;
}
.postal-field button {
    width: 50%;
    padding: 1%;
    border: none;
    background-color: #E6AA9B;
    margin-left: 1%;
    color: white;
    font-weight: bold;
    margin-top: 3%;
}
.home-banner-col-items img {
    width: 17%;
    position: absolute;
    right: 12px;
    top: 20px;

}
.category-col-item {
    background-color: #C73B1F;
    padding: 13% 4% 11% 11%;
    color: white;
    border-top-left-radius: 40px;
}
.text-category hr {
    background-color: #F7DC82;
    border: none;
    width: 39%;
    height: 4px;
    margin-left: -3%;
    margin-top: -115%;
    margin-bottom: 35%;

}
.text-category{
    text-align: center;
    margin-left: 10%;
}

.text-category .light-button{
    margin-left: 8%;
}

.category-row div {
    height: 29em;
}
.category-block {
    padding-right: 1%;
    position: relative;
    margin-top: 4%;
}

.question-category ,.category-category,.sign-up-category {

    background-position: center!important;
}

.products-title hr {
    background-color: #F7DC82;
    border: none;
    width: 40%;
    height: 4px;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.category-inside {
    background-color: white;
    padding: 0px!important;
    border-top-right-radius: 40px;
    float: left;
    width: 80%;
    float: left!important;
    margin-left: 10%;
    margin-bottom: 3%;
}
.category-meat a h3 {
    background-color: #994E37;
    width: 100%;
    padding: 13% 8% 8% 8%;
    text-align: center;
    color: white;
    height: 114px;
    margin-bottom: 0px!important;
}
.category-cheese a h3 {
    background-color: #F9E109;
    width: 100%;
    padding: 13% 8% 8% 8%!important;
    height: 114px;
    text-align: center;
    padding: 20px;
    color: white;
    margin-bottom: 0px!important;
}
.highlighted-title h2 {
    font-family: "Flamante SemiSlab";
    color: #000000;
    text-align: center;
    margin-top: 20%;
}
.highlighted-title hr {
    background-color: #F7DC82;
    border: none;
    width: 40%;
    height: 4px;
    margin-left: 30%;
    margin-top: 0%;
    margin-bottom: 15%;
}

.highlighted-left-image {
    background-image: url(/static/media/bert-home.b5ee6f04.png);
    background-color: #929A23;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30%;
}
.qoute-highlighted {
    position: absolute;
    opacity: 0.6;
    font-family: "Flamante SemiSlab"!important;
    z-index: 80;
    font-size: 10em;
    margin-top: -23%;
    margin-left: -17%;
}
.highlighted-title-boer {
    position: absolute;
    width: 80%;
    font-family: "Flamante SemiSlab";
    color: white;
    font-size: 2em;
    bottom: 10px;
    left: 50px;
    z-index: 99;
    line-height: 1.2;
}
.highlighted-right {
    background-color: #929A23;
    padding: 8% 5% 19% 5%;
    border-bottom-right-radius: 40px;
}

.recipes-right h2 {
    font-family: "Flamante SemiSlab";
    color: white;
    text-align: left;
    margin-top: 8%;
}
.recipes-right hr {
    background-color: #F7DC82;
    border: none;
    width: 40%;
    height: 4px;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.recept-1,.recept-2,.recept-3,.recept-4{
    background-size: cover!important;
}
.border-space {
    padding-right: 1%!important;
    padding-bottom: 10%;
}
.find-row h2 {
    font-family: "Flamante SemiSlab";
    color: #000000;
    text-align: center;
    margin-top: 18%;
    line-height: 1.3;
}
.find-row hr {
    background-color: #F7DC82;
    border: none;
    width: 40%;
    height: 4px;
    margin-left: 30%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.filter button {
    margin-right: 2%;
    margin-bottom: 2%;
    padding: 3% 5%;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center!important;
    background-color: #F0EFEF;
    color: #848484;
    border-radius: 0;
    border: none;
}
.map-col {
    margin-top: 5%;
    margin-bottom: 154%!important;
    width: 50em;
    margin-right: -13%;

}

.participant {
    border-top-left-radius: 40px!important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 47em;
}
.logo-company img{
    width: 50%;
    object-fit: contain;
    margin-top: -85%;
    margin-left:25%!important;
}
.load-more{
margin-bottom: 25%;
margin-top: 5%;
}

.participant-content h3 {
color: black;
font-size: 2em;
margin-top: 4%!important;
}
.participant-details-row img {
width: 104%;
margin-left: -2%!important;
height: 19em;
object-fit: cover;
position: relative;
z-index: 0;
}
.logo-company {
position: absolute;
right: 8em;
top: 66%;
background-color: white;
height: 200px;
width: 200px;
border-radius: 500px;
}
.logo-company img {
width: 50%!important;
object-fit: contain;
margin-top: -15%!important;
margin-left: 25%!important;
}
.recept-getting-ready {
margin-top: 30%;
padding: 2%;
}
.participant-list {
list-style: none;
width: 90%!important;
padding-bottom: 1%;
padding-top: 1%;
border-bottom: black solid 1px;
float: left;
margin-right: 1%;
margin-left: 5%;
}
.participant-list-full .desc-participants-list {
text-align: left!important;
width: 100%;
margin-top: 3%;
margin-bottom: 4%;
}
.participant-list-full img {
width: 6%;
margin-right: 2.5%;
}

.change-row{display: none;}

.download-div a{
    position: fixed;
    right: 36px;
    bottom: 31px!important;
    background-color: black!important;
    border:none!important;
    padding: 1% 2%!important; 
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.download-div a:hover{
opacity: 0.8;
text-decoration: none;
}

.participant-secundaire-image img {
width: 100%;
height: 19em;
object-fit: contain;
}

.participant-secundaire-image{
margin-bottom: 15%;
}
.recepts-left {
    background-color: #929A23;
    padding: 8%;
    margin-right: 0%!important;
    margin-top: 5%;
}
.recepts-left .light-button {
    width: 40%;
    background-color: transparent;
    padding: 1% 3%;
    margin-top: 2%!important;
    border: solid 1px white;
    color: white;
    float: left;
}
.recept-item-sidebar{
    display: none;
}

.recept-item-info {
    margin-top: 9%;
padding: 8%;
}
.recept-ingrdients{
    margin-left:4%;
    margin-right:4%;
    padding: 4%;
    margin-top: -2%;
}
.recept-getting-ready {
    margin-top: -1%;
    padding: 6%;
}
.recept-getting-ready ul li {
    list-style: none;
    margin-bottom: 7%;
}
.text-category hr {
    background-color: #F7DC82;
    border: none;
    width: 39%;
    height: 4px;
    margin-left: -3%;
    margin-top: -136%;
    margin-bottom: 14%;
}
.recepts-details-row img {
    width: 110%;
    margin-left: -2%;
    height: 14em;
    z-index: 0;
}
.left-title {
    left: 2.5em!important;
}

.title-recepts-detail {
    position: absolute;
    right: 20em;
    margin-top: -34%;
    background-color: #929A23;
    width: 41%;
    text-align: left;
    padding: 3% 4%;
    font-size: 14pt;
    color: white;
    font-weight: 800;
    z-index: 10;
}

.recepts-details-row {
    width: 104%;
    height: 14em;
    z-index: 0;
}
.recept-item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 2%;
    margin-bottom: -27%;
}
.recept-getting-ready {
    margin-top: 30%;
    padding: 6%;
}
.green-title h2 {
    color: #929A23;
    font-size: 2em;
}
.blog-image {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 16%;
}

.sitemap{
    margin-top: 15%;
}

.footer-list{
    padding-left: 10%;
}

.mobile-top{
    margin-top: 0%;
}

.little-mobile {
    height: 13em!important;
}

.category-link {
    position: absolute;
    bottom: 0;
    width: 50%;
    margin-left: -28%!important;
    margin-bottom: 10%;
    color: white;
    text-align: center;
    font-family: "Flamante SemiSlab";
    font-size: 1.5em;
}

.logo-mobile-plateau {
    position: absolute;
    bottom: 10px;
    width: 30%!important;
    margin-left: 31%!important;
    margin-bottom: 21%!important;
}
.overlay-row {
    position: fixed;
    background-color: rgba(0,0,0,.651);
    z-index: 999;
    width: 101%;
    height: 100%;
    top: 0;
    padding: 10%;
    margin-left: 10%;
}

.save-changes-home {
    margin-left: -10%;
}
.save-button {
    position: absolute;
    margin-top: -3%;
    bottom: 190px;
    right: 51px;
    background-color: green!important;
    border: green solid 1px;
}
.close-button {
    position: absolute;
    margin-top: 5%;
    bottom: 190px;
    left: 60px;
}

}