@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,800;0,900;1,400&display=swap');


@font-face {
    font-family: "Flamante SemiSlab";
    src: url('../assets/fonts/Flamente/Flamante-SemiSlab-Medium-FFP2.ttf');
    font-weight: bold;
  }

  @font-face {
    font-family: "Photograph Signature";
    src: url('../assets/fonts/Photograph-Signature.ttf');
    font-weight: normal;
  }

  @font-face {
    font-family: "Gaspar-normal";
    src: url('../assets/fonts/Gaspar-Font/gaspar-regular.ttf');
    font-weight: normal;
  }

  @font-face {
    font-family: "Gaspar-bold";
    src: url('../assets/fonts/Gaspar-Font/Gaspar_Bold.ttf');
    font-weight: bold;
  }

  @media only screen and (min-width: 2440px){
    .four {
      background-image: url(/static/media/background-4-groot.b3fb6835.jpg);
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center;
      height: 70vh!important;
  }
  .two {
    background-image: url(/static/media/background-2-groot.b6be7bfd.jpg);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    height: 38vh!important;
}

.three {
  background-image: url(/static/media/background-3-groot.5b039607.jpg);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  height: 50vh!important;
}
  }
 
html,body{
    background-color: rgb(241, 241, 241)!important;
    
}

.link-to-post{
    color:white;
    border-top-left-radius: 5px;
}

h1{
 font-family: 'Gaspar-bold', sans-serif;
 font-weight: 900;
}


h2{
    font-family: 'Gaspar-bold', sans-serif;
    font-weight: 700;
    font-size: 18pt;
   }


   h3{
    font-family: 'Gaspar-bold', sans-serif;
    font-weight: 600;
    font-size: 17pt;
   }

   p{
    font-family: 'Gaspar-normal', sans-serif;
    font-weight: 400;
    font-size: 11pt;
   }

   label{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
   }

.products-table table{
    width: 102.5%;
    margin-left: -1.4%;
   
}

.products-table table tr{
    padding-top: 2%;
    padding-bottom: 2%!important;
    
}

.carousel{
    box-shadow: none;
}
.image-slider {
    width: 80%;
    margin-left: 10%;
    margin-top: 25%;
}

.image-slider img{
    width: 50%!important;
    
}

.products-table .active td{
    background-color: cornflowerblue!important;
    color: white;
}

.products-table  table tr th{
    width: 20%;
    padding: 2%;
    background-color: white;
 
}
.products-table  table tr td{
    width: 20%;
    padding: 2%;
    background-color: white;

}

.products-actions{
width: 100%!important;
padding: 1%;
background-color: white;
margin-bottom: 2%;

}

.products-actions a{
    margin-right: 1%;
    border: solid 1px rgb(105, 104, 104);
    padding: 1%;
    border-radius: 5px;
    color: black;
    float: left;
}

.products-actions a:hover{
    background-color: rgb(99, 96, 96);
    color: white;
    text-decoration: none;
}

.delete-button{
    padding: 3%;
    background-color: red;
    border: red solid 1px;float:left;
    margin-right:2%;
}

.delete-button:hover{
    background-color: rgb(150, 5, 5);
    border: rgb(150, 5, 5) solid 1px;float:left;
}

.align-button-right{
    float: right!important;
}
.add-product-colum{
    background-color: white;
    padding: 4% 2%;
    margin-bottom: 3%;
    
}

.colum-left{

    padding-right: 3%;
}

.add-product-colum input{
    width: 100%;
    padding:1%;
}

.rdw-editor-wrapper{
    border: solid 0.5px rgba(129, 128, 128, 0.137);
    border-radius: 5px;
}

.editorClassName{
    min-height: 100px;
    padding-left: 3%;
}

.add-product-colum select{
    width: 100%;
    padding:1.5%;
    margin-bottom: 3%;
}

.right-product-add-colom{
    padding: 7% 4%!important;
}

.right-product-add-colom span{
    color: gray;
}

.add-product-colum input[type="file"]{
    width: 75%!important;
    border: solid 1px black;
    border-radius: 5px;
}

.add-product-colum button{
    float: right;
    padding: 2%;
}
.product-images {
    padding: 0%;
    padding-left: 2%;
    
}

.product-images img{
    margin-top: 5%;
    width: 100%;
    border-radius: 5px;
}

.nothing-present{
    color: blue;
}
.input-price{
font-size: 12pt;
}

.input-price input{
    width: 95%;
    margin-left: 3%;
}

.add-product-colum input[type="checkbox"]{
    width: 2%;
    margin-right: 2%;
    
}

.variations-row button, .categories-products button{
    float: left!important;
    margin-top: 3%;
}

.last-col{
    background-color: transparent!important;
    margin-bottom: 10%;
}
.categories-products ul {
    padding-left: 0%;
}
.categories-products ul li{
list-style: none;
}

.main-save-button{
    float: right;
    padding:1.2%;
}

.error-message-product{
    float: right!important;
    padding: 1% 1%;
    margin-right: 5%;
    color: red;
    border: solid red 1px;
    border-radius: 5px;
}

.input-colom-profile{
    background-color: white;
    padding: 2%;
    border-radius: 5px;;
}

.input-colom-profile input,.input-colom-profile select{
    width: 100%;
    padding: 1%;
}

.input-colom-profile-50-50 input{
    padding: 2%;
}

.add-company-logo input[type="file"]{
    width: 75%!important;
    border: solid 1px black;
    border-radius: 5px;
}

.input-colom-profile button{
    float: right;
    padding: 2%;
}

.input-colom-profile-transparant img{
    width: 80%;
}

.input-colom-profile-50-50 select{
    width: 100%;
    padding: 2.5%;
}
.timeslots label{
    font-weight: 800;
}
.timeslots ul{
    padding-left: 0%;
}
.timeslots ul li{
    list-style: none;
    border-bottom: solid black 1px;
    padding:3%;
    padding-bottom: 4%;
    padding-top: 4%;
}

.timeslots button{
    text-align: right;
    background-color: transparent;
    border: solid 1px red;
    float: right;
    color: red;
}

.timeslots button:hover{
    color: white;

    background-color: red;
    border: solid 1px red;
}

#main-logo{
    width: 65%;
    margin-left: 15%;
    margin-top: 5%;
    margin-bottom: -5%;
}

.item-icon-wrapper{
    width: 20px;
    margin-right: 18px;
}

.main-navbar .navbar {
    height: 5.75rem;
}

.message-table{
    margin-bottom: 5%;
}

.message-table table{
    width: 102.5%;
    margin-left: -1.4%;
   
}

.message-table table tr{
    padding-top: 2%;
    padding-bottom: 2%!important;
    
}

.message-table .active td{
    background-color: cornflowerblue!important;
    color: white;
}

.message-table  table tr th{
    width: 25%;
    padding: 2%;
    background-color: white;
 
}
.message-table  table tr td{
    width: 20%;
    padding: 2%;
    background-color: white;

}
.button-list li{
  margin-bottom: 4%;

}
.button-list button{
    float: right!important;
    margin-top: 0%;
    border: solid 1px red;
    background-color: red;
}

.button-list button:hover{
    color: white;

    background-color: rgb(145, 7, 7);
    border: solid 1px rgb(145, 7, 7);
}

.button-onimage button{
    margin-top: 5%;
    position: absolute;
    float: right;
    margin-left: 73%;
    margin-top: 5%;
    z-index: 99;
}

.add-button{margin-top: 3%;}

.login-col{
    padding: 15% 10%;
    background-color: white;
}

.add-button-settings{
    background-color: #e94e1b!important;
    border: #e94e1b 1px solid!important;
}

.add-button-settings:hover{
    background-color: #c54318!important;
}

.login-col img{
    width: 25%;
}

.login-col h3{
    margin-top: 8%;
    color:black
}

.login-col p{
    margin-bottom: 3%;
}

.login-col input{
    width: 90%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.login-col button{
    width: 50%;
    background-color: #e94e1b;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border:none;
    color: white;
}

.login-col button:hover{
    background-color: #c9471c;
}

.sign-on-col{
    padding: 15% 10%;
    background-color: white;
}
.sign-on-col img{
    width: 25%;
    margin-left: 37.5%;
}

.sign-on-col h3{
    margin-top: 8%;
    color:black;
    text-align: center;
}

.sign-on-col p{
    margin-bottom: 3%;
    text-align: center;
}

.sign-on-col input{
    width: 100%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.sign-on-col button{
    width: 50%;
    background-color: #47B362;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border:none;
    color: white;
}

.sign-on-col button:hover{
    background-color: #3d9653;
}

.login-image-bg{
    background-image: url("../../src/assets//images/login-bg.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.ck-content{
    min-height: 200px;
}

.descript-undertitle{
    width: 100%;
    float: left;
    margin-top: 2%;
    margin-bottom: 5%;
    font-style: italic;
}

.complete-row{
    margin-top: 5%;
}

.logout-button{
    position: absolute;
      bottom: 0;
      left: 0;
    font-weight: bold;
    padding: .9375rem 1.5625rem;
    background-color: #28654B;
    width: 100%;
    color: white;
  }

  .logout-button:hover{
    background-color: #31815e;
    cursor: pointer;
  }

  .sign-on-col select{
    padding: 10px;
    margin-top: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    cursor: auto;
    border-radius: 50px;
    width: 100%;
    margin-left: 3%;
    margin-bottom: 5%;

  }

  .input-check{
      width: 2%!important;
      float: left;
    margin-top: 4.5%!important;

  }

  .input-check-label{
    width: 93%!important;
    float: right;
   font-size: 9pt;
   font-weight: 400;
  }

  .sign-on-col h4{
      font-weight: 800;
      color: black;
  }

.input-check-span{
margin-top: -3%!important;
}

.sing-on-button{
    width: 50%!important;
    margin-left: 25%;
}

.error-message{
    width: 100%;
    padding: 3%;
    border: solid red 1px;
    margin-top: 3%;
    border-radius: 5px;
    color: red;
    padding-top: 5%;
    text-align: center;
}

.add-variation{
    width: 100%;
    padding: 5% 4%!important;
    margin-top: 13%!important;
}

.impression-picture{
    width: 100%;
    margin-top: 5%;
}

.changed-row{
    background: black;
    position: fixed;
    display: inline;
    width: 85%!important;
    height: 51px!important;
    bottom: 0px!important;
    padding: 1% 1%;
    margin-left:-2%;
    z-index: 999;
}

.changed-col{
   
}

tr{
    border-bottom: rgba(0, 0, 0, 0.24) 1px solid; 
}

.changed-col p{
    color: white;
    float: left;
    width: 80%;
    margin-top: 0%!important;
}

.changed-button{
    float: right;
    width: 10%;
    margin-right: 3.5%;
  margin-top: -0.7%!important;
}

.openingstijden{
    margin-bottom: 5%;
}

.pdfviewer iframe{
    width: 100%;
    height: 800px;
}

.fill-in-form{
width: 100%;
}

.fill-in-form input,.fill-in-form select{
    width: 100%;
    padding:1%;
}

.home-banner-col{
    background-image: url("../../src/assets/images/streekproductengids/frontend/background-image.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
}

.home-banner-col-items{
    text-align: center;
    padding: 15% 5% 10% 5%;
}

.home-banner-col-items h2{
    color: white;
    line-height: 1em;
    font-family: "Flamante SemiSlab";
    font-size: 5em;
}

.home-banner-col-items h3{
    color: white;
    margin-bottom: 8%;
    font-family: "Photograph Signature";
    font-size: 5em;
    font-weight: 300;
}
.home-banner-col-items p{
    color: white;
   font-family: "Gaspar-normal";
   font-size: 1.5em;
}

.home-banner-col-items img{
    width: 10%;
    position: absolute;
    right: 0;
}

.sign-on-button{
    width: 30%;
    background-color: #E6AA9B;;
    padding: 2% 5%;
    margin-top: 2%!important;
    border:none;
    color: white;
    margin-left: 35%;
    float: left;
   
}


.light-button{
    width: 30%;
    background-color: #E6AA9B;;
    padding: 2% 5%;
    margin-top: 2%!important;
    border:none;
    color: white;
    margin-left: 35%;
    float: left;
}
.postal-field label{
    background-color: white;
    padding:1%;
    border:none;
}

.postal-field input{
    width: 30%;
    padding:1%;
    border:none
}

.postal-field button{
    width: 15%;
    padding:1%;
    border: none;
    background-color: #E6AA9B;
    margin-left: 1%;
    color:white;
    font-weight: bold;
}

.postal-field button:hover{
    background-color: #cc9486;
}

.category-col-items{
    margin-top: -4%;
}

.category-col-item{
    background-color: #C73B1F;
    padding:5% 5% 4% 5%;
    color: white;
    border-top-left-radius: 40px;
}

.category-col-item h2{
    color: white;
    font-family: "Flamante SemiSlab";
    font-size: 3em;
}

.category-col-item p{
    line-height: 1.4em;
    font-family: "Gaspar-normal";
    font-size: 1.2em;
}

.category-block{


  padding-right:1%;
  position: relative;
  margin-top: -8%;
    
}

.sign-up-category{
    background-image: url("../../src/assets/images/streekproductengids/frontend/streekproductengids-categorie.png");
    background-color: white;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 50px;
    
}

.local-cagtegory{
    background-image: url("../../src/assets/images/streekproductengids/frontend/streekproducten-bekijken-categorie.png");
    background-color: white;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 50px;
}

.question-category{
    background-image: url("../../src/assets/images/streekproductengids/frontend/buy-products.png");
    
    background-color: white;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 50px;
}

.category-link{
  position: absolute;
    bottom: 0;
    width: 50%;
    
    margin-left: -25%!important;
    margin-bottom: 10%;
    color:white;
    font-family: "Flamante SemiSlab";
    
}

.category-link:hover{
    opacity: 0.8;
    color:rgb(226, 224, 224);
    text-decoration: none;
}



.category-row div{
 height: 35em;


}
.text-category hr{
background-color:#F7DC82;
border:none;
width: 60%;
height: 4px;
margin-left: 0%;
margin-top: -70%;
margin-bottom: 35%;
transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}

.text-category{
position: absolute;
margin-top: 90%;
width: 70%;
margin-left: 15%;
}

.light-button{
    width: 80%;
    margin-top: 10%!important;
    margin-left: 0%;
    padding:4%;
    text-align: center;
    background: transparent;
    color:#838383;
    border:#838383 solid 1px;
}

.light-button:hover{
    background: #838383;
    color:white;
    border:#424141 solid 1px;
    text-decoration: none;
}

.products-row{
    background-color: #FCF7F7;
    margin-top: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    
}

.products-title h2{ 
    font-family: "Flamante SemiSlab";
    color:#000000;
}

.products-title hr{
    background-color:#F7DC82;
border:none;
width: 10%;
height: 4px;
margin-left: 0%;
margin-top: 0%;
margin-bottom: 5%;
}
.category{
    padding:1%;
  
   
}
.category-inside{
    background-color: white;
    padding: 0px!important;;
    border-top-right-radius: 40px;
    float: left;
    width: 100%;
}




.category img{
   width: 30%;
   height: 80px;
   margin-left: 35%;
   margin-top:20%;
   margin-bottom:20%;
   background-color: white;
}

.category-meat a h3{
    background-color: #994E37;
    width: 100%;
    padding:8%;
    text-align: center;
    color:white;
    height: 114px;
    
    margin-bottom: 0px!important;
}

.category-fish a h3{
    background-color: #61AAB8;
    width: 100%;
    padding:8%;
    height: 114px;
    text-align: center;
    padding:40px;
    color:white;
    margin-bottom: 0px!important;
}

.category-cheese a h3{
    background-color: #F9E109;
    width: 100%;
    padding:8%;
    height: 114px;
    padding-top:15%!important;
    text-align: center;
    padding:20px;
    color:white;
    margin-bottom: 0px!important;
}

.category-drinks a h3{
    background-color: #656D78;
    width: 100%;
    padding:8%;
    height: 114px;
    text-align: center;
    padding:40px;
    color:white;
    margin-bottom: 0px!important;
}

.category-bread a h3{
    background-color: #D77D41;
    width: 100%;
    padding:8%;
    height: 114px;
    text-align: center;
    padding:40px;
    color:white;
    margin-bottom: 0px!important;
}

.category-patato a h3{
    background-color: #67A829;
    width: 100%;
    padding:8%;
    height: 114px;
    text-align: center;
    padding:20px;
    color:white;
    margin-bottom: 0px!important;
}


.category-dry a h3{
    background-color: #F6AA19;
    width: 100%;
    padding:8%;
    height: 114px;
    text-align: center;
    padding:40px;
    color:white;
    margin-bottom: 0px!important;
}

.category-ice a h3{
    background-color: #F59CA1;
    width: 100%;
    padding:8%;
    height: 114px;
    text-align: center;
    padding:40px;
    color:white;
    margin-bottom: 0px!important;
}

.highlighted-row{
    margin-top: 4%;
}

.highlighted-title h2{ 
    font-family: "Flamante SemiSlab";
    color:#000000;
    text-align: center;
}

.highlighted-title hr{
    background-color:#F7DC82;
border:none;
width: 10%;
height: 4px;
margin-left: 45%;
margin-top: 0%;
margin-bottom: 5%;
}

.highlighted-left-image{
    background-image: url("../../src/assets/images/streekproductengids/frontend/bert-home.png");

    background-color: #929A23;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding:5%;
}

.highlighted-right{
    background-color: #929A23;
    padding:8% 5% 7% 5%;
    border-bottom-right-radius: 40px;
}

.highlighted-right h2{
   color:#FFD445;
}

.highlighted-right p{
color: white;
}

.highlighted-right a{
    width: 30%;
    padding:2% 4%;
    background-color: transparent;
    border: solid white 1px;
    color:white;
    position: relative;
    z-index: 999;
}

.highlighted-right a:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.267);
}

.highlighted-title-boer{
    position: absolute;
    width: 80%;
    font-family: "Flamante SemiSlab";
    color:white;
    font-size: 3em;
    bottom: 10px;
    left: 300px;
    z-index: 99;
}
.qoute-highlighted{
    position: absolute;
    opacity: 0.6;
    font-family: "Flamante SemiSlab"!important;
    z-index: 80;
    font-size: 10em;
    margin-top: -35%;
    margin-left: -25%;
}

.recipes-right{

padding:5%;
}

.recipes-row{
    background-image: url("../../src/assets/images/streekproductengids/frontend/recepten-wallpaper.jpg");

    background-color: #929A23;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 8%;
}

.recipes-right h2{ 
    font-family: "Flamante SemiSlab";
    color:white;
    text-align: left;
}

.recipes-right hr{
    background-color:#F7DC82;
border:none;
width: 10%;
height: 4px;
margin-left: 0%;
margin-top: 0%;
margin-bottom: 5%;
}

.recipe-col{
    background:linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("../../src/assets/images/streekproductengids/frontend/recept-dummy.jpg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding:3%;
    height: 13em;
    padding-top: 17%!important;
    text-align: right;
    border-top-right-radius: 40px;
    color: white;

}
.recept-1{
    background:linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.5)), url("../../src/assets/images/streekproductengids/recepten/recept-1.jpg")!important;
    background-position: center!important;
    background-size: contain!important;
}

.recept-2{
    background:linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.5)), url("../../src/assets/images/streekproductengids/recepten/recept-2.jpg")!important;
    background-position: center!important;
    background-size: contain!important;
}

.recept-3{
    background:linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.5)), url("../../src/assets/images/streekproductengids/recepten/recept-3.jpg")!important;
    background-position: center!important;
    background-size: contain!important;
}

.recept-4{
    background:linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.5)), url("../../src/assets/images/streekproductengids/recepten/recept-4.jpg")!important;
    background-position: center!important;
    background-size: contain!important;
}

.recipe-col h4{
   font-weight: bold;
   line-height: 1.3;
  color:#F7DC82;
    font-size:  11pt;
    
}
.border-space{
    padding-right:1%!important;
    padding-bottom: 3%;

}

.find-row{
    margin-top: 8%;

}

.find-row h2{ 
    font-family: "Flamante SemiSlab";
    color:#000000;
    text-align: center;
}

.find-row hr{
    background-color:#F7DC82;
border:none;
width: 10%;
height: 4px;
margin-left: 45%;
margin-top: 0%;
margin-bottom: 5%;
}

.find-title p{
    text-align: center;
}

.find-title .postal-field{
    margin-left: 12%;
    border: solid black 1px;
    padding:2%;
}

.map{
    width: 300;
    height: 300;
}

.desktop-menu{
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 2px -2px gray;
    
}

.desktop-menu .logo-section img{
    width: 80%;
    margin-left: 60%;
    padding: 4%;
    margin-top: 2%;
    }

    .desktop-menu .sub-logo-section img{
        width: 30%;
        margin-right: 90%!important;
        padding: 4%;
        margin-top: 2%;
    }
    

.menu-section {
    padding-top: 1.5%!important;
    padding-bottom: 1.5%!important;
    padding-left: 10%!important;
  
}
.sub-page .menu-section .active-desktop-menu{
    color:white!important;
}

.sub-page .header-section{
    margin-top: 4%;
}

.desktop-menu .menu-section{
    width: 100%!important;
    padding-left: 10%;
  
}

.desktop-menu .menu-section a{

 padding: 1% 3%;
 color: black;
margin-right: 1%;
margin-top: 4%;
font-family: 'Gaspar-bold';

}

.active-desktop-menu,.desktop-menu .menu-section a:hover{

    border-bottom:#C2C453 solid 2px;

    text-decoration: none;
}

.mobile-menu{
    display: none;
}

.footer-section {
    background-color: black;
  
    margin-top: 5%;
    margin-left: -1%;
    width: 102%;
    border-top-left-radius: 80px;
}

.footer-section h3{
    font-size: 24pt;
    color: white;
    padding-top: 4%;
}

.news-letter-footer h4{
    font-size: 24pt;
    color: white;
    font-weight: 800;
    padding-top: 3%;
}

.footer-section ul li{
    list-style: none;
    padding-left: 0%!important;
}

.footer-section ul {
    padding-left: 0%!important;
    color: white;
}

.bottom-line{
    text-align: center;
    color: white;
}

.bottom-line hr{
    width: 100%;

    border-color: white;
}
.news-letter-footer-row{
    border-top-left-radius: 80px;
}
.news-letter-footer{
    text-align: center;

}

.news-letter-footer h4{
    font-size: 18pt;
}

.news-letter-footer input{
    width: 40%;
    padding: 1% 2%;
    border-radius: 11px;
    border:none;
}

.news-letter-footer  form{
    margin-bottom: 3%;
}

.footer-list{
    margin-top: 5%;
}

.footer-list ul li,.footer-list ul li a{
    margin-bottom: 2%;
    color: white;
}

.news-letter-footer button{
    background-color: #4FAC81;
    border-radius: 11px;
    border: none;
    padding: 1% 2%;
    color: white;
}

.search-colum select{
    width: 100%;
    margin-top: 2%;
    padding: 2%
    
}

.opacity{
    opacity: 0.6!important;
}

.search-button{
    background-color: #28654B;
    padding: 3% 10%!important;
    color: white;
    text-align: center;
    width: 100%;
    float: left;
    border-radius: 10px;
}


.participant-title h2{ 
    font-family: "Flamante SemiSlab";
    color:#000000;
    text-align: center;
    margin-top: 12%;
}

.participant-title hr{
    background-color:#F7DC82;
border:none;
width: 10%;
height: 4px;
margin-left: 45%;
margin-top: 0%;
margin-bottom: 2%;
}

.participant-title  p{
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}


.particioant-col{
    padding:1%;
}
.participant{
    border-top-left-radius: 40px!important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
 
  height: 45em;
}

.participant-content{
    padding:8%!important;
}
.participant img{
    width: 100%;
    border-top-left-radius: 40px!important;
    height: 20em;
    object-fit: cover;

}

.participant-content i{
    margin-right: 3%;
}

.participant-content h3{
    color: black;
    font-size: 11pt;
    margin-top: 4%!important;
}
.participant-content p{
   margin-top: 8%;

}

.participant-category{
    width: 100%;
    float: left;
    margin-top: 1%;
    margin-bottom: 4%;
}

.participant-content a{
    
    background-color: #929A23;
    
    padding:3% 13%;
    color:white!important;
   
position: relative;
bottom:0;
}

.filter h4{
    text-align: center;
    color: black;
}

.filter button{
    margin-right: 2%;
    margin-bottom: 2%;
    padding:1% 4%;
    align-items: center;
    background-color: #F0EFEF;
    color:#848484;
    border-radius: 0;
    border:none;
}

.filter button:hover{
    background-color: #C73B1F!important;
    color:white!important;
}
.active-category{
    background-color: #C73B1F!important;
    color:white!important;
}

.recepts-row{
    margin-top: 5%;
}

.recepts-left .light-button{
    width: 20%;
    background-color: transparent;
    padding: 1% 3%;
    margin-top: 2%!important;
    border:solid 1px white;
    color: white;
    
    float: left;
}

.recepts-left .light-button:hover{
    background-color: rgba(226, 226, 226, 0.418);
}

.recepts-left{
    background-color: #929A23;
    padding:5%;
    margin-right: 0%!important;
}

.recepts-left h3{
    color:#FFD445;
}

.recepts-left p{
color:white;
}

.recepts-right{
margin-left: -4%;
border-bottom-right-radius: 40px!important;
}

.recepts-right img{
    border-bottom-right-radius: 40px!important;
    width: 120%;
  
    object-fit: contain;
}
.recepts-details-row{
width: 104%;

height: 25em;

z-index: 0;
}

.recepts-details-row img{
    width: 104%;
    margin-left: -2%;
    height: 25em;
    
    z-index: 0;
    }

    .home-blog img{
        height: 35em;
    }
.recepts-details-row{
    background-color:#929A23 ;
}

.title-recepts-detail{
    position: absolute;
    right: 15em;
    margin-top: -10%;
    background-color:#929A23 ;
    width: 20%;
    text-align: left;
    padding:1% 4%;
    font-size: 14pt;
    color:white;
    font-weight: 800;
    z-index: 10;
}

.title-recepts-detail hr{
margin-bottom: 0%;
border: solid 1px #F7DC82;
width: 40%;
margin-left: 0%;
margin-top: 4%;
z-index: 10;
}

.left-title{
    left:8.5em!important;
}

.recept-item-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recept-item-image h2{
    color:black;
}

.recept-details-container{
    padding-top: 8%;
}

.recept-details-info-cols{
    border: solid 1px #707070;
    padding:10% 2% 5% 2%;
    text-align: center;
}

.recept-details-info-cols h4{ 
color:black;
}

.recept-item-sidebar{
    padding-left:5%;
    position: fixed;
    right: 10%;
    width: 30%;
}

.recept-item-sidebar h5{
    color: black;
    margin-bottom: 8%;
}

.recept-item-sidebar ul {
    padding-left: 0%;
}
.recept-item-sidebar a{
color:black;

}
.back-button-recepts{
    border: black 1px solid;
    padding: 1.5% 8%;
    color:black;
}

.back-button-recepts:hover{
text-decoration: none;
background-color: rgba(0, 0, 0, 0.192);
}

.recept-item-sidebar ul li{
    list-style: none;
    padding-left: 0%!important;
    margin-bottom: 3%;
    padding-bottom: 3%;
    border-bottom: solid 1px #000000;
}

.recept-ingrdients{
    margin-top: 8%;
    padding:2%;
    border: solid 1px #707070;

}

.recept-ingrdients h3{
    color:black
}

.recept-ingrdients ul{
    padding-left: 0%;
}

.recept-ingrdients ul li{
list-style: none;
margin-bottom: 2%;
}

.recept-getting-ready{
    margin-top: 5%;
    padding:2%;
}

.recept-getting-ready h3{
color: black;
}
.recept-getting-ready ul{
    padding-left: 0%;
}

.recept-getting-ready ul li{
list-style: none;
margin-bottom: 2%;
}

.green-title h3{
    color:#929A23;
    font-size:20pt;
}

.green-title h2{
    color:#929A23;
    font-size:24pt;
}

.participant-details-row img{
    width: 104%;
    margin-left:-2%!important;
    height: 40em;
    object-fit: cover;
    position: relative;
    z-index: 0;
}

.participant-details-container ul{
 padding-left: 0%;
}

.participant-list{
list-style: none;
width: 48%;
padding-bottom: 1%;
padding-top: 1%;
border-bottom: black solid 1px;
float: left;
margin-right: 1%;
}

.participant-list-full{
    width: 97%;
   
}

.participant-list-full .desc-participants-list{
    text-align: left!important;
    width: 70%;
}

.participant-list .desc-participants-list input{
    width:140%;
    margin-left: -40%;
}

.desc-participants-list select{
    width: 100%;
    padding:1%;
}

.participant-list-full .desc-participants-list input{
width: 100%;
margin-left: 0%;
}


.desc-participants-list input{
padding:1%;
}
.participant-list img{
width: 6%;
margin-right: 5%;
}

.participant-list-full img{
    width: 3%;
    margin-right: 2.5%;
}

.title-participants-list{
    font-size: 10pt;
    font-weight: bold;
    margin-top: 30%!important;
    padding-top: 30%!important;

}

.desc-participants-list{
    text-align: right!important;
    float: right;
    font-size: 10pt;
}

.logo-company{
    position: absolute;
    right: 140px;
    top:80%;
    background-color: white;
    height: 200px;
    width: 200px;
    border-radius: 500px;

}

.logo-company img{
    width: 50%;
    object-fit: contain;
    margin-top: -95%;
    margin-left:25%!important;
}

.map div{

    z-index: 999;
    width: 80%;
}

.map-col{
    margin-top: 5%;
    margin-bottom: 40%;
    width: 80%;
    margin-left: -3%;
}

.participant-secundaire-image img{
width: 100%;
height: 40em;
object-fit: contain;
}

.change-div button{
    position: fixed;
    right: 36px;
    bottom: 31px!important;
    background-color: black!important;
    border:none!important;
    padding: 1% 2%!important; 
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.change-div button:hover{
opacity: 0.8;
text-decoration: none;
}

.change-div a{
    position: fixed;
    right: 36px;
    bottom: 31px!important;
    background-color: black!important;
    border:none!important;
    padding: 1% 2%!important; 
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.change-div a:hover{
opacity: 0.8;
text-decoration: none;
}
.save-div button{
    position: fixed;
    right: 36px;
    bottom: 31px!important;
    background-color: green!important;
    border:none!important;
    padding: 1% 2%!important; 
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.save-div button:hover{
opacity: 0.8;
text-decoration: none;
}

.close-div button{
    position: fixed;
    right: 220px;
    bottom: 31px!important;
    background-color: white!important;
    border:black 1px solid!important;
    padding: 1% 2%!important; 
    border-radius: 50px;
    z-index: 800;
    color: black;
}

.close-div button:hover{
    background-color: rgb(211, 209, 209)!important;
text-decoration: none;
color: black;
}

.overlay-row{
    position: fixed;
    background-color:rgba(0, 0, 0, 0.651);
    z-index: 999;
    width: 103%;
   height: 100%;
   top: 0;
   padding:10%;
   margin-left: -2%;
}

.overlay-row-home{
    position: fixed;
    background-color:rgba(0, 0, 0, 0.651);
    z-index: 999;
    width: 103%;
   height: 100%;
   top: 0;
   padding:10%;
   left: 0;

}



.overlay-col-home{
    background-color: white;
    padding:5%;
    border-radius: 5px;
}
.save-changes-home{
    margin-left: -2%;
}

.overlay-col{
    background-color: white;
    padding:5%;
    border-radius: 5px;
}

.overlay-col form{
margin-bottom: 4%;
}

.input-download-home input{
 width: 100%;
 margin-bottom: 2%;
 padding:0.5%;
}

.change-logo{
    position: absolute;
    z-index: 10;
    right: 160px;
    top: 420px;
    border-radius: 50px;
    padding: 1% 1% 1% 1.2%;
    text-align: right!important;
}

.change-primaire-logo{
    position: absolute;
    z-index: 10;
    right: 160px;
    top: 120px;
    border-radius: 50px;
    padding: 1% 1% 1% 1.2%;
    text-align: right!important;
}

.close-button{
    position: absolute;
    margin-top: 5%;
    bottom:30px;
    left:60px;
}

.loading-company{
    margin-top: 30%;
    margin-left: 24%;
}

.error-form{
    margin-top: -4%!important;
    position: absolute;
    color:red;
}

.send-form{
  
}

.loading-primaire{
    margin-top: 20%;
    margin-left: 45%;
    margin-bottom: 15%;
}
.change-secundaire p{
text-align: right;
margin-right: 8%;
font-weight: 800;
}

.color-green{
    color:green
}


.color-red{
    color:red
}

.save-changes{
    margin-left: -37%!important;
}


.save-changes input{
width: 100%;
margin-bottom: 2%;
}



.save-button:hover{
    background-color: rgba(0, 128, 0, 0.767)!important;
}

.blog-image{
    width: 40%;
    margin-left: 30%;
    margin-bottom: 5%;
}

.cta-menu{
    background-color: #C73B1F!important;
    color:white!important;
    margin-left: 10%!important;
}

.cta-menu:hover{
    opacity: 0.8;
    border-bottom: none!important;
}

.load-more button{
    width: 50%!important;
    border-radius: 0;
    margin-left:25%;
    margin-top: 10%;
    background-color: #929A23;
    border:#929A23 solid 1px;
}

.load-more button:hover{
opacity: 0.8;
background-color: #929A23;
border:#929A23 solid 1px;
}

.link-recept-home:hover{
    text-decoration: none;
    opacity: 0.5!important;
}

.recept-kok img{
    width: 70%;
    margin-left: 25%;
}

.recept-kok p{
    text-align: left;
    margin-top: 0%;
}

.new-singip-image{
    height: 40em;
    background-color: gray;
}
.new-singip-image h2{
color:white;
text-align: center;
padding-top: 20%;
}

.new-singip-image-logo{
    height: 10em;
    background-color: #9c8f8f;
}

.new-singip-image-logo h2{
    color:white;
    text-align: center;
    padding-top: 22%;
}


.participant a:hover{
    cursor: pointer;
}

.edit-screen-detail{
    width: 80%;
    margin-left: 10%;   
    border: solid 1px black;
    border-radius: 5px;
    padding: 5%;
    padding-bottom: 10%;
    background-color: white;
  }

  .edit-screen-detail li{
    list-style: none;
    width: 100%;
    margin-bottom: 3%;
    margin-top: 1%;
  }

  .align-left{
    text-align: left;
    width: 30%;
}

.align-right{
    float: right;
    width: 10%!important;
}

.align-left-expand{
    width: 30%;
    float: left;
    margin-top: 1.5%;
}
.align-right-expand{
    width: 70%;
    float: right;
}

.align-right-expand input{
    width: 100%;
    padding: 1%;
}

.align-right-actions{
    float: right;
    width: 30%;
}

.arrow-up{
    transform: rotate(180deg);
}

.align-right input{
    width: 100%;
    padding: 1%;
    margin-top: -1%;
}
.textarea textarea{
    width: 100%;
    margin-top: 2%;
    padding: 1%;
}

.save-button{
    float: right!important;
    padding: 1.5% 6%!important;
    background-color: #E74F28!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: white!important;
    font-size: 15px!important;
    border-style: none!important;
    width: 25%;
    margin-right: 9%!important;
}

.save-button:hover{
    background-color:  #9b5341!important;
    color: white!important;
}

.small-settings-section{
    background-color: white;
    border-radius: 5px;
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-style: solid;
    border-width: 1px;
    border-color: #70707038;
}

.small-settings-section li{
    list-style: none;
    padding: 2% 4% 3% 4%;
    border-bottom-style: solid;
    border-bottom-color:  #70707038;
    border-bottom-width: 1px;
}

.align-left{
    text-align: left;
    width: 30%;
}

.align-right{
    float: right;
    width: 70%;
}

.align-left-expand{
    width: 30%;
    float: left;
    margin-top: 1.5%;
}
.align-right-expand{
    width: 70%;
    float: right;
}

.align-right-expand input{
    width: 100%;
    padding: 1%;

  
    
}

.category-item{
    text-align: left;
    width: 70%;
}

.align-right-actions{
    float: right;
    width: 30%;
}

.arrow-up{
    transform: rotate(180deg);
}

.align-right input{
    width: 100%;
    padding: 1%;
    margin-top: -1%;
}

.settings-div{
    padding: 5% 4%;
}

.recover-button{
    float: left!important;
    padding: 1.5% 6%!important;
    background-color: transparent!important;
    border-style: solid!important;
    border-color: #E74F28!important;
    border-width: 2px!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: #E74F28!important;
    font-size: 15px!important;
    width: 25%;
}

.recover-button:hover{
    background-color: #a73013!important;
    color: white!important;
}

.delete{
    border-color: red!important;
    border-width: 2px!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: red!important;
}

.delete:hover{
    background-color: red!important;
    color: white!important;
}

.save-button{
    float: right!important;
    padding: 1.5% 6%!important;
    background-color: #E74F28!important;
    border-radius: 50px!important;
    margin-top: -1.5%!important;
    color: white!important;
    font-size: 15px!important;
    border-style: none!important;
    width: 25%;
}

.save-button:hover{
    background-color:  #9b5341!important;
    color: white!important;
}

.margin-top{
    margin-top: 4%;
    padding-bottom: 3%;
}

.margin-top h4{
    margin-bottom: 4%;

}

.svg-icon-actions{
    width: 3%!important;
    margin-right: 4%;
    float: right;
}

.textarea textarea{
    width: 100%;
    margin-top: 2%;
    padding: 1%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    float: right;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .edit-screen-detail{
    width: 80%;
    margin-left: 10%;   
    border: solid 1px black;
    border-radius: 5px;
    padding: 5%;
    padding-bottom: 10%;
    background-color: white;
  }

  .edit-screen-detail li{
    list-style: none;
    width: 100%;
    margin-bottom: 3%;
    margin-top: 1%;
  }

  .marginTop .post-it-update,   .marginTop .post-it-close{
      padding-top: 6.5%!important;
      margin-right: 4%!important;
  }

  .marginTop button{
      margin-top: 5%!important;
  }

  .chosen-sets{
      width: 100%;
  }

  .app-table{
    width: 100%;
    margin-top: 4%;
}

.app-overview-table-row td{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #707070;
    padding-bottom: 2%;
    padding-top: 2%;
}
.app-table tr th{
    width: 20%;
}

.app-table-icon{
    float: left;
    margin-top: -3.5%!important;
}

.app-table-firstrow{
    padding-top: 3.5%;
    width: 10%!important;
}


.app-table-title{
    margin-left: 6%;
    font-weight: 800;
    font-size: 15px;
    width: 100%;
    margin-top: 6%!important;
    padding-top: 3%;
}

.app-table-po{
    margin-left: 6%;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
}

.app-table-row td{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #707070;
    padding-bottom: 4%;
    padding-top: 4%;
}

.list-item-selection li{
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 3%;
    list-style: none;
    width: 100%;
    padding: 2%;
    background-color: white;
}

.list-item-selection li .align-right{
    float: right;
}

.two-block-white{
    background-color: white;
    border-width: 1px;
    padding: 4% 4% 4% 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 4%;
  }
  
  
  .four-block-white{
    background-color: white;
    padding: 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 4%;
    border-width: 1px;
  
  }
  
  .one-block-white{
    background-color: white;
    padding: 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 4%;
    border-width: 1px;
  
  }
  
  .one-block-white li{
  list-style: none;
  }
  
  .one-block-white h4{
    font-weight: bolder;
  }
  
  .one-block-white input, .one-block-white textarea{
    border-radius: 10px;
    padding: 2%;
    border-width: 1px;
  }
  .status{
  float: right;
  color:#FFB100;
  }
  
  .action{
    float: right;
    margin-right: 0%;
  }
  
  .table-head{
    font-weight: bold;
  }
  
  .status-head{
    float: right;
  
    }
  
    .add{
      margin-right: 8%;
    }
  
  
    .post-view-col{
      height: 200%;
      
    }
    
  
    .post-it-view{
      position: relative;
      background-color: white;
      padding: 4%;
      top: 10%;
      left: 25%;
      font-size: 50px;
  
      width: 50%;
      border-radius: 5px;
      margin-bottom: 10%!important;
    }
  
    .post-it-view input,.post-it-view textarea,.post-it-view select{
      width: 100%;
      margin-bottom: 4%;
      font-size: 14pt;
    }
    .post-it-view textarea{
      height: 15vh;
      margin-bottom: -5%;
    }
  
    .post-it-update{
      font-size: 10pt;
      color: green;
      padding-top: 5.8%!important;
      float: right;
    }
  
    .post-it-close{
      font-size: 10pt;
      text-decoration: underline;
      float: left;
    }
  
    .post-it-delete{
      font-size: 10pt;
      text-decoration: underline;
      float: right;
      color: red;
    }
  
    .post-it-view h6{
  
      margin-bottom: 0%;
    }
  
    .post-it-textarew{
      margin-bottom: 0%!important;
    }
  
   label{
      font-size: 12pt!important;
    }
  
    [type="checkbox"]
  {
      vertical-align:middle;
  }
    
    .action-head{
      float: right;
      margin-right: 0%;
    }
  
    .one-block-white input, .one-block-white textarea{
      width: 100%;
      margin-bottom: 2%;
      font-size: 14pt;
    }
  
  
  .two-block-blue{
  
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ED6A1C;
    border-width: 1px;
    padding: 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 3.8%;
    width: 100%;
  }
  
  .overlay{
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 998; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
  
  
  
  .scrum-image{
    width: 71%;
  }
  
  .scrum-title{
    color:white;
  }
  
  .scrum-date{
    font-size: 32pt;
    color: white;
    margin-bottom: 5%;
  }
  .apm-board{
    height: 1080px!important;
    width: 1920px!important;
    margin-top: 5%;
  }
  
  .my-desk-block{
    background-color: #f2f2f2!important;
    padding: 5%;
    margin-top: 14%;
    border-radius: 5px;
    height: 70vh;
    border-style: solid;
    border-width: 1px;
    border-color: black;
  }
  
  .box{
    width: 130px;
    padding: 3%;
    margin-bottom: 2%;
    border-radius: 2px;
    box-shadow: 0 24px 50px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    color: white;
    border-style:solid;
    border-width: 1px;
    border-radius: 5px; 
  }
  
  .box:hover{
    cursor: pointer;
  }
  
  .error-text{
    color:red;
  }
  
  .one-block-white select{
    width: 100%;
  }
  
  .add-new-post__editor .ql-container,
  .add-new-post__editor .ql-editor {
    min-height: 400px;
  }
  
  .nav-ffort .nav-item .nav-link{
    color: black!important;
  }
  .nav-ffort .nav-item .nav-link .material-icons{
    color: black!important;
  }
  
  .nav-link.active{
    border-left-color: #ED6A1C!important;
    border-left-width: 1px!important;
  }
  
  .main-sidebar .nav-wrapper{
    background-color: #fff!important;
  }
  
  .min-twee{
    background-image: url('/assets/images/background-dummy.jpg');
  }
  
  .settingsBlocks{
    background-color: #ED6A1C;
    padding: 10% 5% 10% 5%;
    border-radius: 20px;
    margin-bottom: 10%;
  }
  .active-block{
    opacity: 0.5;
    background-color: #ED6A1C;
    padding: 10% 5% 10% 5%;
    border-radius: 20px;
  }
  
  .active-block a{
    color: white!important;
    display: flex;
    justify-content: center;
  
  }
  
  .settingsBlocks a{
    color: white!important;
    text-align: center;
    display: flex;
    justify-content: center;
  
  }
  
  .settingsBlocks i{
    color: white;
    font-size: 24pt;
    display: flex;
    justify-content: center;
  }
  
  .settingsBlocksCat i{
    color: white;
    font-size: 24pt;
    display: flex;
    justify-content: center;
  }
  
  .settingsBlocksCat{
    background-color: #ED6A1C;
    padding: 20% 5%;
    border-radius: 20px;
    width: 90%;
    height: 90%;
    overflow-wrap: break-word;
  }
  
  
  .version-control{
  z-index: 999;
  margin-top: -7%;
    position: absolute;
    left: 3;
    margin-left: 2%;
  
  font-weight: bold;
  }
  .logout-button{
    position: absolute;
      bottom: 0;
      left: 0;
    font-weight: bold;
    padding: .9375rem 1.5625rem;
    background-color: #e94e1b;
    width: 100%;
    color: white;
  }
  
  .logout-button:hover{
    background-color: #FF8136;
  }
  .titles-row{
    margin-bottom: 2%;
  }
  
  .text-middle{
    text-align: center;
    margin-top: -24%;
    margin-bottom: 24%;
  }
  
  .title-middle{
    text-align: center;
  }
  
  
  
  
  .image-steps{
    width: 40%;
    margin-left: 30%;
    margin-top: 5%;
  }
  
  .logo-ffort{
    width: 30%;
    margin-top: 10%;
    margin-bottom: 5%;  
    margin-left: 35%;
  
  }
  
  .delted-action i{
    margin-left: 50%;
    color: red;
  }
  
  .delted-action i:hover{
    opacity: 0.5;
  }
  .image-steps-title{
    text-align: center;
    margin-top: 13%;
    margin-bottom: 13%;
    color: white;
    font-weight: bold;
  
  }
  
  .input-field-sets{
    width: 100%;
    border-bottom-style: solid;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 2%;
    border-bottom-color: grey;
    border-bottom-width: 1px;
  }
  
  .image-steps-select{
  color: white;
  }
  
  .bord-settings{
  margin-top: 2%;
  }
  
  .titles-setup{
    font-weight: bold;
  
  }
  
  .button-setup{
    background-color: #ED6A1C!important;
      border-radius: 50px!important;
      padding: 2% 8%!important;
  
      font-weight: bold!important;
      border-color: #ED6A1C!important;
      
  }
  
  .button-reset{
    background-color: #ED6A1C!important;
      border-radius: 50px!important;
      padding: 2% 8%!important;
  
      font-weight: bold!important;
      border-color: #ED6A1C!important;
      margin-bottom: 5%;
  }
  
  .participants{
    margin-top: 5%;
    margin-bottom: -5%!important;
  }
  
  .button-cat{
    background-color: #ED6A1C!important;
    border-radius: 50px!important;
    font-weight: bold!important;
    border-color: #ED6A1C!important;
    width: 100%;
    margin-top: 15%;
    padding: 4% 8%!important;
  }
  
  .button-add{
    background-color: #ED6A1C!important;
      border-radius: 50px!important;
      padding-left: 8%!important;
      padding-right: 8%!important;
      font-weight: bold!important;
      border-color: #ED6A1C!important;
      width: 100%;
      margin-top: 15%;
  }
  
  .button-add-category{
    background-color: #ED6A1C!important;
      border-radius: 50px!important;
      padding-left: 8%!important;
      padding-right: 8%!important;
      font-weight: bold!important;
      border-color: #ED6A1C!important;
      width: 30%;
      margin-top: 3%;
  }
  
  .link-setup{
    background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    padding: 1%;
    font-weight: 700!important;
    border-color: #ED6A1C!important;
    color: white!important;
    font-size: 9pt;
  }
  
  .link-setup-2{
    background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    padding: 2%;
    font-weight: 700!important;
    border-color: #ED6A1C!important;
    color: white!important;
    font-size: 9pt;
  }
  
  .link-setup-afronden{
    background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    padding: 2.5%;
    font-weight: 700!important;
    border-color: #ED6A1C!important;
    color: white!important;
    font-size: 9pt;
    width: 30%;
    text-align:  center;
    position: absolute;
    z-index: 999;
  }
  
  .link-setup-categorie{
    background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    padding: 1%;
    font-weight: 700!important;
    border-color: #ED6A1C!important;
    color: white!important;
    font-size: 9pt;
    width: 75%;
    text-align:  center;
    margin-top: 5%!important;
  }
  
  .button-adding{
    margin-top: 2%;
  }
  
  .button-sorting{
    background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 10%!important;
    padding-right: 10%!important;
    font-weight: bold!important;
    padding-top: 3%!important;
    padding-bottom: 3%!important;
    width: 100%;
   
  }
  
  .bord-settings input{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid;
    border-bottom-width: 1px;
    padding: 2%;
    width: 50%;
  }
  
  .bord-settings li{
    list-style: none;
    border-bottom-style: solid;
    width: 80%;
    padding-bottom: 2%;
    border-bottom-width: 1px;
    margin-bottom: 2%;
  }
  .archive-list{
    margin-bottom: 5%;
  }
  
  .error-message{
    background-color: rgba(255, 0, 0, 0.8);
    padding: 3%;
    padding-bottom: 0.2%;
    border-radius: 20px;
    color: white;
    margin-top: 3%;
  }
  
  .archive-list h6{
    padding-bottom: 1%;
    margin-top: 5%;
  
  }
  
  .archive-list li{
    list-style: none;
    border-bottom-style: solid;
    width: 100%;
    padding-bottom: 1%;
    border-bottom-width: 1px;
    margin-top: 1%;
  }
  
  .bottom-col{
    margin-bottom: 10%;
  }
  
  .modal-background{
    height: 100vh;
    background-size: cover;
  }
  
  .start-block{
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-block-left{
  
    background-color: white;
    border-style:solid;
    border-width: 1px;
    border-radius: 20px;
    margin-top: 8%;
    padding-bottom: 3%;
    padding-top: 2.5%;
    border-right-style: solid;
    margin-bottom: 3%;
  }
  
  .login-block-left h3{
    text-align: center;
    font-weight: bolder;
    font-family: 'Montserrat' ;
  }
  
  .login-block-left h4{
    text-align: center;
    font-family: 'Montserrat' ;
  }
  
  .login-block-left p{
    text-align: center;
    font-family: 'Montserrat' ;
    font-weight: 300;
  }
  
  .login-block-left input{
    width: 70%;
    margin-left: 15%;
    padding:1%;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #707070;
    padding-left: 3%;
  }
  
  .login-block-left button{
    margin-left: 13%;
    width: 74%;
    padding: 2%;
  }

  /* Reverse mode */
.inverso {
    font-weight: bold;
  }
  .inverso span {
    line-height: 80px;
    transform-origin: bottom center;
  }
  
  .category-one{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 50%;
    top: 20%;
  }
  
  .category-one-three{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 52%;
    top: 27%;
  }
  
  .category-two-three{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 45%;
    top: 43%;
  }
  
  .category-third-three{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 38%;
    top: 27%;
  }
  
  .category-one-four{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 52%;
    top: 25%;
  }
  
  .category-one-five{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 51%;
    top: 20%;
  }
  
  .category-one-seven{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 49%;
    top: 20%;
  }
  
  .category-two{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 54%;
    top: 34%;
  }
  
  .category-two-four{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 51%;
    top: 40%;
  }
  
  .category-two-five{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 53%;
    top: 35%;
  }
  
  .category-two-seven{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 53%;
    top: 31%;
  }
  
  .category-third{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 50%;
    top: 45%;
  }
  
  .category-third-four{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 39%;
    top: 39%;
  }
  
  .category-third-five{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 45%;
    top: 45%;
  }
  
  .category-third-seven{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 53.5%;
    top: 42%;
  }
  
  .category-four{
    position: absolute;
    width: 153px;
    text-align: center;
    color: white;
    left: 37%;
    top: 45%;
  }
  
  .category-four-four{
    position: absolute;
    width: 153px;
    text-align: center;
    color: white;
    left: 37%;
    top: 25%;
  }
  
  .category-four-five{
    position: absolute;
    width: 153px;
    text-align: center;
    color: white;
    left: 34%;
    top: 36%;
  }
  
  .category-four-seven{
    position: absolute;
    width: 120px;
    text-align: center;
    color: white;
    left: 44%;
    top: 49%;
  }
  
  .category-five{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 35%;
    top: 31%;
  }
  
  .category-five-five{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 38%;
    top: 22%;
  }
  
  .category-five-seven{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 37%;
    top: 42%;
  }
  
  .category-six{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 40%;
    top: 20%;
  }
  
  .category-six-seven{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 35%;
    top: 30%;
  }
  
  .category-seven-seven{
    position: absolute;
    width: 100px;
    text-align: center;
    color: white;
    left: 40.5%;
    top: 21.5%;
  }
  
  .e-checkbox-wrapper{
    margin-right: 2%;
    height: 10px;
  }
  
  .margin-bottom-min{
    margin-bottom: 0%!important;
  }
  
  .question-list{
    font-size: 12pt;
  
  }
  
  .post-it-view li{
    list-style: none;
    font-size: 12pt;
  
  }
  .see-list-delete{
    font-size: 12pt;
  }
  
  .delete-icon{
    color:red;
    float: right;
  }
  
  .no-participants{
    font-size: 12pt;
  }

  .screen-rondo{
    margin-top: 10%;
  
  }
  
  .log-block{
    background-color: #e0e0e0;
    margin-top: 2%;
    margin-left: 10%!important;
    padding: 2% 3%;
    border-left-style: solid;
    border-left-width: 3px;
    border-left-color: #e94e1b;
  }
  
  .title{
    font-weight: bold;
    margin-top: 2%!important;
    margin-bottom: 2%!important;
  }
  
  .log-block a{
    color: white!important;
    background-color: #e94e1b;
    padding: 0.8% 1.5%;
    font-size: 13px;
    margin-top: 4%;
    border-radius: 51px;
  }
  .log-block a:hover{
    color: white!important;
    background-color: #be4218;
    padding: 0.8% 1.5%;
    font-size: 13px;
    margin-top: 4%;
    border-radius: 51px;
  }
  
  .pie{
    display: block;
    height: 219%!important;
    width: 64%!important;
    position: absolute!important;
    margin-top: 7.8%!important;
    margin-left: 15.8%!important;
  }
  
  .great-doughnut{
    display: block;
    height: 185%!important;
    width: 185%!important;
    position: absolute!important;
    z-index: 0!important;
    margin-left: -44.7%!important;
    margin-top: -22.5%!important;
  }
  
  .middle-doughnut{
    height: 100%!important;
    width: 125.2%!important;
    position: absolute;
    margin-left: -14.8%;
    margin-top: -7.5%;
  }
  
  .reservation-block{
    background-color: #f2f2f2!important;
    padding: 5%;
    margin-bottom: 10%;
    border-radius: 5px;
    height: 220px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
  
  }
  
  .reservation-block-image{
    padding: 5%;
    margin-bottom: 2%;
    border-radius: 5px;
    height: 200px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    background-repeat: no-repeat;
    background-size: 260px 200px;
  }
  
  .two-block-white{
    background-color: white;
    border-width: 1px;
    padding: 4% 4% 4% 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 4%;
  }
  
  
  .four-block-white{
    background-color: white;
    padding: 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 4%;
    border-width: 1px;
  
  }

  
  .one-block-white{
    background-color: white;
    padding: 4%;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 4%;
    border-width: 1px;
  
  }
  
  .one-block-white li{
  list-style: none;
  }
  
  .one-block-white h4{
    font-weight: bolder;
  }
  
  .one-block-white input, .one-block-white textarea{
    border-radius: 10px;
    padding: 2%;
    border-width: 1px;
  }
  .status{
  float: right;
  color:#FFB100;
  }
  
  .action{
    float: right;
    margin-right: 0%;
  }

  .apm-board{
      overflow-x:initial!important;
  }


.board-setup{
    width: 70%!important;
}

.board-setup-users{
    width: 60%!important;
    margin-top: -1%;
}

.selected-categories{
    position: absolute;
    margin-top: 5%;
    margin-left: 10%;
}

.selection-button{
  padding: 1%;
  float: right;
  margin-top: -4%;
  background-color: #e94e1b;
  color: white;
  border-radius: 50px;
  width: 20%;
  text-align: center;
}

.start-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 10%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-bottom: 3%;
 
}

.sorting-row{
  padding-left: 14%;
}
.sorting-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-top: 1%;
  padding-bottom: 3%;
 width: 90%;
 margin-right: 0.15%;

}

.sorting-block h5{
  margin-bottom: 5%;
  font-weight: bold;
  font-size: 21pt;
  border-bottom: solid;
  padding-bottom: 3%;
  
}

.sorting-block li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}

.block-middle{
  padding-top: 5%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling{
  padding-top: 2%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling h5{
  font-weight: bolder;
  font-style: italic;
}

.block-middle-stelling-qsort{
  padding-top: 1%!important;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling-qsort h5{
  font-weight: bolder;
  font-style: italic;
}
.block-middle h5{
  font-weight: bolder;
}

.block-middle h6{
  text-align: center;
}
.block-middle-text{
  margin-top: 2%;
  text-align: center;
}

.block-middle-text p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.block-middle-text-question{
  margin-top: 2%;
  text-align: center;
}
.block-middle-text-question p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 18pt;
}

.block-middle-text-left{
padding-top: 5%;
}

.disagrees-table{
  border-color: #ED6A1C;
  border-width: 3px;
}

.disagrees-table h5{
  border-color: #ED6A1C;
}

.naturals-table{
  border-color: #ED6A1C;
  border-width: 3px;
  background-color: #F2B28C;
  color: white;
}

.naturals-table h5{
  border-color: #ED6A1C;
  color: white;
}
.agrees-table{
  border-color: #ED6A1C;
  border-width: 3px;
  background-color: #ED6A1C;
  color: white;
}

.marginbottom{
  margin-bottom: 5%;
}

.agrees-table h5{
  border-color: white;
  color: white;
}


.image-check{
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category{
  color: #ED6A1C;
  font-weight: bold;
  margin-left: 2%;
  margin-right: 2%;
}

.date-list{
  margin-left: 2%;
  margin-right: 2%;
}

.see-list{
  float: right;
  margin-right: 2%;
}

.see-list-delete i{
  float: right;
  margin-right: 1%;
  color:#FF0000;
  margin-top: 5.5%;
}

.see-list-delete a{
  float:right;
  text-decoration: underline!important;
}

.see-list-delete:hover a{
  color:#FF0000!important;
}

.see-list-edit i{
  float: right;
  margin-right: 1%;
  margin-top: 5.5%;
}

.question-list{
  margin-right: 2%;

}

.question-items{
  margin-right: 2%;
  margin-left: 2%;
}

.select-box-bord{
  width: 50%;
  background-color: white;
  border-radius: 0px!important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 2%;
}

.nav-ffort .nav-item .nav-link.active, .nav-ffort .nav-item .nav-link:hover{
box-shadow: inset 0.1875rem 0 0 #ED6A1C!important;
}

.active, .nav-link:hover{
  background-color: #c0632d71!important;
}

.custom-Class div{
  width: 100%!important;
}

.position-title{
  border-bottom-style: solid;
  border-bottom-width: 2px;

  margin-bottom: 5%;
}
.position-title h3{
  text-align: center;
  background-color: white;
  margin-bottom: 4%!important;
}

.position-positive div{
  background-color: #ED6A1C;
}


  .one{
    background-image: url('./images/background-1-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 18.5vh;
  }
  
  .two{
    background-image: url('./images/background-2-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 35.5vh;
  }
  
  .three{
    background-image: url('./images/background-3-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 44.5vh;
  }
  .four{
    background-image: url('./images/background-4-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 60vh;
  }

  .position-negative div{
    background-color: #f1c8b180!important;
    padding: 0px!important;
  
  }
  
  .position-negative div div{
    padding: 10px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
    padding: 17px!important;
  }
  
  .position-natural div{
    background-color: #F2B28C;
    padding: 0px!important;
  }
  
  .position-natural div div{
    background-color: #F2B28C!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }
  
  .position-positive div{
    background-color: #ED6A1F;
    padding: 0px!important;
  }
  
  .position-positive div div{
    background-color: #ED6A1F!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }

  .qsortdone{
margin-top: 2%;
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50px;
 position: absolute;
 right: 40px;
z-index: 999;
  }
  
  .qsortdone button{
    width: 100%!important;
    padding: 3%!important;
    margin-left: 2%!important;
    margin-right: 2%!important;
  }
  
  .button-sortdone{
    background-color: #e94e1b!important;
      border-radius: 50px!important;
      padding: 2% 8%!important;
  
      font-weight: bold!important;
      border-color: #e94e1b!important;
      
  }
  
  .button-restart{
    background-color: white!important;
      border-radius: 50px!important;
      padding: 2% 8%!important;
      color: #757575!important;
      font-weight: bold!important;
      border-color: #ED6A1F!important;
      
  }
  .comment-section textarea{
    width: 50%;
    height: 50px;
    border-bottom-width: 2px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
  }
  .link-setup-comment{
    width: 18%;
      padding: 1% 3%!important;
      background-color: white!important;
      color: black!important;
      border-width: 2px!important;
      border-style: solid;
      border-color: #e94e1b!important;
      font-size: 9pt;
      border-radius: 50px!important;
      font-weight: 700!important;
  }
  .link-setup-comment:hover{
    background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding: 1% 3%!important;
    font-weight: 700!important;
    border-color: #e94e1b!important;
    color: white!important;
  }

  .block-middle-text input{
    width: 100%;

    padding:1%;
  }

  .block-middle-text a{
    margin-top: 5%;
  }

  .start-block{
margin-top: 3%!important;
  }
.blocks{
  margin-top: 5%;
}
  .block-left{
    margin-left: 15%;
  }

  .group-left{
    margin-left: 7%;
  }

  .class-group-natural div,.class-group-agree div,.class-group-disagree div  {
    width: 100%!important;
   
  }

  .class-group-natural div div,.class-group-agree div div,.class-group-disagree div  div{
width: 50%!important;
float: left;
  }

  .class-group-natural div{
    background-color: #F2B28C!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }

  .class-group-agree div{
    background-color: #ED6A1F!important;
    color: white!important;
    padding: 17px!important;
    border-color: white!important;
    border-style: solid;
    border-width: 2px;
  }

  .class-group-disagree div{
    border-color: #ED6A1F!important;
    background-color: #f1c8b1!important;
    border-style: solid;
    border-width: 2px;
    padding: 17px!important;
    color:black!important;
  }

  .position-negative div div{
    border-color: #ED6A1F!important;
    background-color: #f1c8b1!important;
  }

  .url-q-sort{
    float: left;
    margin-top: 2%;
  }

  .company-logo-block{
    width: 8%;
    position: absolute!important;
    top:10px;    
    right: 40px;
  }

.Drops{
  margin-top: 5%;
}



.error-message-qsort {
  background-color: rgba(255, 0, 0, 0.8);
  /* padding: 3%; */
  padding:3% 3% 3% 5%;

  border-radius: 20px;
  color: white;
  margin-top: 3%;
  position: absolute;
  width: 134%;
  left: -461px;
}

.error-message-qsort p{
  margin-bottom: 0%;
}

.error-inside-qsort{
  color:#e94e1b;
}

.add-product-colum ul{
list-style: none;
padding-left: 0%;
}

.q-sort-back{
  background-color: transparent!important;
  margin-bottom: -4%;
}

.q-sort-back input{
  background-color: white;
  border: none;
  padding: 2% 3% 2% 3%;
  border-radius: 5px;
}

.add-product-colum ul li{
background-color: white;
padding: 2% 3% 2% 3%;
margin-bottom: 1%;

border-radius: 5px;
}

.company-logo-left{
  width: 20%;
  position: absolute;
  top: 10px;
  left:40px;
}

.q-sort-change-question{
    margin-bottom: 2%;
}

.link-setup-changes{
    float: right;
}

.add-product-colum textarea{
    width: 100%;
    min-height: 20vh;
    padding:1%;
}

.whitetext{
  color: white!important;
}

.text-areas-check{
  margin-top: 5%;
}

.text-areas-check textarea{
  width: 100%;
  padding:2%;
  min-height: 20vh;
}

.color-orange{
  color:#e94e1b!important;
}

.small{
  width: 20%!important;
  padding: 1%!important;
  margin-left: -10%!important;
  margin-right: 50%!important;
}

.small:hover{
  opacity: 0.6;
}